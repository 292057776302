.popoverRoot {
    padding: 15px
}

.title {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.titleIcon {
    font-size: 17px;
    margin-left: 5px;
}