.supportBtn {
    display: inline-block;
    width: 100%;
    color: #479bd2;
    padding: 8px 15px;
    border-radius: 32px;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    border: 1px solid rgba(71, 155, 210, 0.5);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.supportBtn:hover {
    color: #fff;
    background-color: #479bd2;
}

.overview-btn {
    text-decoration: none;
    line-height: 22px;
    color: #18345E;
    font-weight: 700;
    opacity: 0.8;
    display: block;
    width: -webkit-fill-available;
    padding: 8px 16px;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.overview-btn:hover {
    background-color: rgba(0, 0, 0, 0.06);
}
