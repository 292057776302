.uploadCandidate {
    min-width: 200px;
    margin-left: 20px !important;
}

.inputAddCandidate {
    width: 100%;
}

.addCandidateSubmit {
    float: right;
}

.uploadContainer {
    display: flex;
    justify-content: end;
}

.actionContainer {
    display: flex;
}

.statsContainer {
    background-color: #ffffff;
    display: flex;
    padding: 10px;
    margin-bottom: 20px;
    align-items: center;
}

.statsText {
    opacity: 0.8;
    margin-right: 10px;
    flex: 1;
}